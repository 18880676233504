import React, { useState } from "react";
import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
    TextField,
    Stack,
    Snackbar,
    Alert,
    CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import StarIcon from '@mui/icons-material/Star';

// Styled components
const GridBackground = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `
    linear-gradient(to right, #3eb48910 1px, transparent 1px),
    linear-gradient(to bottom, #3eb48910 1px, transparent 1px)
  `,
    backgroundSize: '24px 24px',
    transform: 'skewY(-6deg)',
    transformOrigin: '0 0',
    zIndex: 0,
});

const HeroSection = styled(Box)({
    position: 'relative',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: '#fafafa',
});

const FeatureBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(62, 180, 137, 0.05)',
    marginBottom: theme.spacing(1),
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-2px)',
        backgroundColor: 'rgba(62, 180, 137, 0.08)',
    }
}));

const Homepage = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

    const handleWaitlistSubmit = async () => {
        if (!email || !email.includes('@')) {
            setSnackbar({
                open: true,
                message: "Please enter a valid email address",
                severity: "error",
            });
            return;
        }

        setLoading(true);
        try {
            const formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSe4SCqDU5EGzj_BVsfJn-FDLH_yATKuuc1Q9dij3CKY77KQoA/formResponse';
            const formData = new URLSearchParams();
            formData.append('entry.1762464353', email);

            await fetch(formUrl, {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData,
            });

            setSnackbar({
                open: true,
                message: "Successfully joined the waitlist! We'll keep you updated.",
                severity: "success",
            });
            setEmail("");
        } catch (error) {
            console.error("Error submitting to waitlist:", error);
            setSnackbar({
                open: true,
                message: "Failed to join waitlist. Please try again later.",
                severity: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleWaitlistSubmit();
        }
    };

    return (
        <HeroSection>
            <GridBackground />
            <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
                <Grid container spacing={4} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={8} textAlign="center">
                        <Typography
                            variant="h1"
                            sx={{
                                fontSize: { xs: '2.5rem', md: '3.5rem' },
                                fontWeight: 700,
                                mb: 3,
                                color: '#1a1a1a',
                                lineHeight: 1.2,
                            }}
                        >
                            Master Excel Formulas Through Challenges
                        </Typography>
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: { xs: '1.2rem', md: '1.5rem' },
                                mb: 4,
                                color: '#666',
                                fontWeight: 400,
                                lineHeight: 1.5,
                            }}
                        >
                            Engage in challenges, compete on leaderboards, and receive real-time feedback
                            as you level up your Excel and Google Sheets skills.
                        </Typography>

                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ mb: 6 }}
                        >
                            <TextField
                                placeholder="Enter your email"
                                type="email"
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyPress={handleKeyPress}
                                disabled={loading}
                                sx={{
                                    width: { xs: '100%', sm: '300px' },
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'white',
                                    }
                                }}
                            />
                            <Button
                                variant="contained"
                                size="large"
                                disabled={loading}
                                onClick={handleWaitlistSubmit}
                                sx={{
                                    bgcolor: '#3eb489',
                                    '&:hover': { bgcolor: '#35a07a' },
                                    width: { xs: '100%', sm: 'auto' },
                                    py: 1.5,
                                    px: 4,
                                }}
                            >
                                {loading ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    'Join Waitlist'
                                )}
                            </Button>
                        </Stack>

                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={4}>
                                <FeatureBox>
                                    <StarIcon sx={{ color: '#3eb489' }} />
                                    <Typography>Gamified Learning</Typography>
                                </FeatureBox>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FeatureBox>
                                    <StarIcon sx={{ color: '#3eb489' }} />
                                    <Typography>Real-time Feedback</Typography>
                                </FeatureBox>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FeatureBox>
                                    <StarIcon sx={{ color: '#3eb489' }} />
                                    <Typography>Friendly Competition</Typography>
                                </FeatureBox>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </HeroSection>
    );
};

export default Homepage; 